import React, { Component, useState } from "react"
import "../assets/styles/_index.scss"
import Container from 'react-bootstrap/Container'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import { Link } from "gatsby"
import BcdEmprasaPropertySidebar from "./BcdEmprasaPropertySidebar"
import ProjectCtaButton from "../../../projectctabutton/ProjectCtaButton"
import { ArrowLeft, ArrowRight } from 'react-bootstrap-icons'
import { Accordion, Card, Button } from 'react-bootstrap'

import Brochureimage from "/utility/assets/common/details/brochure-image.png";
import Floorplanimage from "/utility/assets/common/details/floor-plan-image.png";
import Projectpriceimage from "/utility/assets/common/details/project-price-image.png";
import Projectvideoimage from "/utility/assets/common/details/project-video-image.png";
import PropTVimage from "/utility/assets/common/prop-tv-image.png";
import DownloadPriceFileModal from "../../../downloadpricefilemodal/DownloadPriceFileModal"
import PriceFileModal from "../../../pricefilemodal/PriceFileModal"
import DownloadFileModal from "../../../downloadfilemodal/DownloadFileModal"
import FileModal from "../../../filemodal/FileModal"

import ProptvStand from "../../../proptvstand/ProptvStand"

import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import ProptvVideoPlayer from "../../../proptvvideoplayer/ProptvVideoPlayer"
import { useLocation } from "@reach/router"
import BcdSarvaBharatPropertySidebar from "./BcdSarvaBharatPropertySidebar"

const videoUrlStand = "https://www.propverse.ai/utility/assets/bcd-developer/BCD-Emprasa-video.mp4"
const thumbnailUrlStand = require("/utility/assets/homepage/prop-tv.png")
const propGpt = require("/utility/assets/common/propGPT.png")
const project = require("/utility/assets/icons/project-list.png")
const about = require("/utility/assets/icons/about-developer.png")
const enquiry = require("/utility/assets/icons/enquiry.png")

const projects = require("/utility/assets/icons/Projects-icon-mob.png")
const abouticon = require("/utility/assets/icons/Abt-icon-mob.png")
const propversetv = require("/utility/assets/icons/Proptv-icon-mob.png")
const infodesk = require("/utility/assets/icons/infodesk-icon-mob.png")

function BcdSarvaBharatProperty() {
  const [isRightSidebarOpen, setIsRightSidebarOpen] = useState(true);

  const toggleRightSidebar = () => {
    setIsRightSidebarOpen(isRightSidebarOpen);
  };


  // const [isImageGalleryOpen, setisImageGalleryOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [isDownlaodModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isDownlaodPriceModalOpen, setIsDownloadPriceModalOpen] = useState(false);



  const openModal = () => {
    setIsModalOpen(true);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openPriceModal = () => {
    setIsPriceModalOpen(true);

  };


  const closePriceModal = () => {
    setIsPriceModalOpen(false);
  };

  const openDownloadModal = () => {
    setIsDownloadModalOpen(true)


  };

  const closedownloadModal = () => {
    setIsDownloadModalOpen(false)
  };

  const openDownloadPriceModal = () => {
    setIsDownloadPriceModalOpen(true)


  };

  const closedownloadPriceModal = () => {
    setIsDownloadPriceModalOpen(false)
  };



  const handleFloorViewButtonClick = () => {
    openModal()
  }

  const handlePriceViewButtonClick = () => {

    openPriceModal()

  }
  const handleBrochureViewButtonClick = () => {

    window.open("/utility/assets/bcd-developer/Sarva-Bharath/BrochureSarvaBharath.pdf")
  }

  const handleBrochureDownloadButtonClick = () => {

    const pdfUrl = '/utility/assets/bcd-developer/Sarva-Bharath/BrochureSarvaBharath.pdf';

    const anchor = document.createElement('a');
    anchor.href = pdfUrl;
    anchor.download = 'BrochureSarvaBharath.pdf';
    anchor.click();

  }

  const handleVideoDownloadButtonClick = () => {
    const videoUrl = '/utility/assets/bcd-developer/Sarva-Bharath/Sarva-Bharath.mp4';
    const anchor = document.createElement('a');
    anchor.href = videoUrl;
    anchor.download = 'Emprasa.mp4';
    anchor.click();
  }

  const handleVideoViewButtonClick = () => {
    window.open("/utility/assets/bcd-developer/Sarva-Bharath/Sarva-Bharath.mp4")

  }


  const handlePriceDownloadButtonClick = () => {
    openDownloadPriceModal()

  }

  const handleFloorDownloadButtonClick = () => {
    openDownloadModal()

  }

  const cardItems = [
    {
      id: 0,
      image: Brochureimage,
      title: "Brochure",
      button1: "Download",
      button2: "View",
      viewButtonClick: handleBrochureViewButtonClick,
      downloadButtonClick: handleBrochureDownloadButtonClick
    },
    {
      id: 1,
      image: Projectvideoimage,
      title: "Project Video",
      button1: "Download",
      button2: "View",
      viewButtonClick: handleVideoViewButtonClick,
      downloadButtonClick: handleVideoDownloadButtonClick
    },
    {
      id: 2,
      image: Projectpriceimage,
      title: "Project Price",
      button1: "Download",
      button2: "View",
      viewButtonClick: handlePriceViewButtonClick,
      downloadButtonClick: handlePriceDownloadButtonClick
    },
    {
      id: 3,
      image: Floorplanimage,
      title: "Floor plan",
      button1: "Download",
      button2: "View",
      viewButtonClick: handleFloorViewButtonClick,
      downloadButtonClick: handleFloorDownloadButtonClick,

    },
  ]

  const files = [
    { name: 'SarvaBharath-FloorPlan', type: 'pdf', url: '/utility/assets/bcd-developer/Sarva-Bharath/FloorPlanSarvaBharat.pdf' },
    // { name: '2BKFloorPlan', type: 'pdf', url: '/utility/assets/bcd-developer/bcd-empressa/2BKFloorPlan.pdf' },
  ];

  const Pricefiles = [
    { name: 'Sarva-Bharath-Pricelist', type: 'pdf', url: '/utility/assets/bcd-developer/Sarva-Bharath/PriceListSarvaBharath.pdf' },
    // { name: 'Emprasa2BK', type: 'pdf', url: '/utility/assets/bcd-developer/bcd-empressa/Emprasa2BK.pdf' },
  ]

  const [showVideoPopup, setShowVideoPopup] = useState(false)

  const location = useLocation()

  const href = location.pathname

  // console.log("locsss", location.pathname);

  const valueToRemove = "/developers/bcd-emprasa-developer/about/"

  const updatedURL = href.replace(valueToRemove, "")

  const openVideoPopup = () => {
    setShowVideoPopup(true)
  }

  const closeVideoPopup = () => {
    setShowVideoPopup(false)
  }

  return (
    <>

      <div className="ProjectDetailPageSectionBcd ProjectCTASection">
        <Container fluid className="BoothBackSection d-none d-md-none">
          <Link to="/developers/abhee-developer/">
            <ArrowLeft /> Back
          </Link>

          <div className="EnquiryBoothSection d-block d-md-none">
            <Image src={enquiry} fluid className="" />
          </div>
        </Container>

        <div className="d-none d-md-block">
          <BcdSarvaBharatPropertySidebar
            isOpen={isRightSidebarOpen}
            toggleRightSidebar={toggleRightSidebar}
          />
        </div>

        <ProjectCtaButton />

        <div className="ProjectResponsiveNavbarProperties">
          <Container className="d-block d-md-none">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                  <Nav.Link href="#" className="menu-blue-navbar">
                    Menu
                  </Nav.Link>
                  <Nav.Link href={"/developers/bcd-emprasa-developer/"}>
                    <Image src={projects} fluid className="" /> &nbsp;Projects
                  </Nav.Link>
                  <Nav.Link href={"/developers/bcd-emprasa-developer/about/"}>
                    <Image src={abouticon} fluid className="" /> &nbsp;About Developer
                  </Nav.Link>
                  <Nav.Link onClick={openVideoPopup}>
                    <Image src={propversetv} fluid className="" /> &nbsp;PropTv
                  </Nav.Link>
                  <Nav.Link href="/information-desk/">
                    <Image src={infodesk} fluid className="" /> &nbsp;Infodesk
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>

        {showVideoPopup && (
          <ProptvVideoPlayer
            ProptvVideoUrl="https://www.propverse.ai/utility/assets/homepage/HallProptv.mp4"
            onClose={closeVideoPopup}
          />
        )}

        <div className="proptv-stand-wrapper d-none d-md-block">
          <ProptvStand
            videoUrlStand={videoUrlStand}
            thumbnailUrlStand={thumbnailUrlStand}
          />
        </div>

        <Container fluid className="ProjectDetailFooterSection">
          <Row>
            <Col lg={4} className="p-0 textAlignCenter">
            </Col>
            <Col lg={4} className="p-0 textAlignCenter">
              <Row className="ProjectDetailListBg d-none d-md-block">
                <Col lg={6} xs={7} className="p-0 pr-3 textAlignCenter">
                  <div className="ProjectDetailList">
                    <Link to="/developers/bcd-emprasa-developer/">
                      <p><Image src={project} fluid className="" /> Project List</p>
                    </Link>
                  </div>
                </Col>
                <Col lg={6} xs={7} className="p-0 pr-3 pr-md-0 textAlignCenter">
                  <div className="AboutDev">
                    <Link to="/developers/bcd-emprasa-developer/about/">
                      <p><Image src={about} fluid className="" /> About&nbsp;Developer</p>
                    </Link>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={4} className="p-0 textAlignRight">
              <Image src={propGpt} fluid className="BoothLogoSection" />
            </Col>
          </Row>
        </Container>
      </div >

      <Accordion defaultActiveKey="0" className="d-block d-md-none ProjectListAccordion ProjectDetailsList">

        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Sarva Bharat
            </Accordion.Toggle>
          </Card.Header>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {cardItems.map((card) => (
                <>
                  <Row className="m-0 mb-2">
                    <div className="ProjectListAccordionWidth">
                      <h6 className='projectdetailslist-card-title'>{card.title}</h6>
                    </div>
                    <div className="ProjectListAccordionWidth">
                      <Button className='card-buttons-download' onClick={card.downloadButtonClick}>{card.button1}</Button>
                    </div>
                    <div className="ProjectListAccordionWidth">
                      <Button className='card-buttons-view' onClick={card.viewButtonClick}>{card.button2}</Button>
                    </div>
                  </Row>
                </>
              ))}
              <div className='footer-link'>
                <Link target="_blank" to="https://www.propex.ai/projects/np-63788-a1-ayodhya-apartment/colive_ppm_53788">
                  <text>Click here for more details <ArrowRight /></text>
                </Link>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>

      </Accordion>

      {isModalOpen && (
        <FileModal isOpen={isModalOpen} onRequestClose={closeModal} files={files} />
      )}


      {isDownlaodModalOpen && (
        <DownloadFileModal isOpen={isDownlaodModalOpen} onRequestClose={closedownloadModal} files={files} />
      )}


      {isPriceModalOpen && (
        <PriceFileModal isOpen={isPriceModalOpen} onRequestClose={closePriceModal} files={Pricefiles} />
      )}

      {isDownlaodPriceModalOpen && (
        <DownloadPriceFileModal isOpen={isDownlaodPriceModalOpen} onRequestClose={closedownloadPriceModal} files={Pricefiles} />
      )}
    </>
  )
}

export default BcdSarvaBharatProperty